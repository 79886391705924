'use client'

import { animated } from '@react-spring/web'
import { useRef } from 'react'

import { useMenuContext } from 'core/context/MenuProvider'
import useOnScrollPushElement from 'core/hooks/useOnScrollPushElement'
import useSession from 'core/hooks/useSession'
import useUIState from 'core/hooks/useUIState'

export default function NavigationHolder({ children }: { children: JSX.Element[] | JSX.Element }): JSX.Element {
  const { options } = useSession()
  const el = useRef<HTMLDivElement>(null)
  const { hasBanner } = useMenuContext()
  const {
    menu: { stickyRef, forcedMenu },
  } = useUIState()

  const translateY = useOnScrollPushElement(el.current, stickyRef, [hasBanner, options])
  const shouldTranslate = !forcedMenu

  return (
    <animated.div
      className="z-1 will-change-transform"
      ref={el}
      style={{ transform: `translateY(-${shouldTranslate ? translateY : 0}px)` }}
    >
      {children}
    </animated.div>
  )
}

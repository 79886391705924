import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client'

export default new InMemoryCache({
  dataIdFromObject: (obj) => {
    switch (obj.__typename) {
      case 'Shop':
        return 'shop'
      default:
        return defaultDataIdFromObject(obj)
    }
  },
  typePolicies: {
    User: {
      fields: {
        //Avoid storing in cache addresses that user have been deleting
        addresses: {
          merge(_, incoming: any[]) {
            return incoming
          },
        },
      },
    },
    Checkout: {
      fields: {
        lines: {
          merge(_, incoming) {
            return [...incoming]
          },
        },
      },
    },
  },
})

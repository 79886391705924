import { useEffect, useState } from 'react'

import useIsWholesale from 'core/hooks/useIsWholesale'
import mergeClasses from 'core/utils/mergeClasses'

import colors from 'components/AdamTheme/theme/colors'
import Button from 'components/Button'

import Logosvg from './assets/logo.svg'

export default function Logo({
  color = 'ink',
  onClick,
  className,
}: {
  className?: string
  onClick?: () => void
  color?: keyof typeof colors
}): JSX.Element {
  const [classNames, setClassName] = useState(`transition-all fill-${color}`)
  const [displayB2B, setB2B] = useState(false)
  const isWholesale = useIsWholesale()

  useEffect(() => {
    setClassName(mergeClasses(`transition-all fill-${color}`, isWholesale && 'scale-75 h-[50px]'))
    setB2B(isWholesale)
  }, [isWholesale, color])

  return (
    <Button
      to={`/`}
      styleType="base"
      onClick={onClick}
      className={mergeClasses(
        'focus pointer-events-auto m-auto flex items-center justify-center text-[0] flex-col',
        className
      )}
    >
      <Logosvg className={mergeClasses(classNames, className)} />
      {displayB2B && (
        <span className={mergeClasses('-translate-y-xs text-xs', `transition-all text-${color}`)}>Wholesale</span>
      )}
      Homepage
    </Button>
  )
}

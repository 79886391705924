'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { Suspense, useEffect } from 'react'

import { useSyncIntercomSessionAttributes } from 'core/clients/intercom/useSyncIntercomSessionAttributes'
import { useMenuContext } from 'core/context/MenuProvider'
import useOverlayControls from 'core/hooks/useOverlayControls'
import mergeClasses from 'core/utils/mergeClasses'
import stringToBoolean from 'core/utils/stringToBoolean'

import Client from 'components/Client'
import Footer from 'components/Footer'
import GdprCookie from 'components/GdprCookie'
import Navigation from 'components/Navigation'
import NewsletterPopover from 'components/NewsletterPopover'

import Maintenance from './Maintenance'

const pagesWithNoFooterAndHeader = [/^(?=.*checkout)(?!.*success)/, 'reports']

export default function Layout({ children }: { children?: React.ReactNode }): JSX.Element {
  useSyncIntercomSessionAttributes()

  const { hasBanner } = useMenuContext()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { show } = useOverlayControls()
  const cart = searchParams?.get('cart') === 'open'

  useEffect(() => {
    if (cart) {
      show('cart')
    }
  }, [cart])

  const hiddenPages = stringToBoolean(process.env.NEXT_PUBLIC_B2B)
    ? [...pagesWithNoFooterAndHeader, 'login', 'password-reset']
    : pagesWithNoFooterAndHeader
  const hideMenuAndFooter = hiddenPages.find((hideRoute) => pathname?.match(hideRoute))

  return !!stringToBoolean(process.env.NEXT_PUBLIC_MAINTENANCE) ? (
    <Maintenance />
  ) : (
    <>
      {hideMenuAndFooter ? <></> : <Navigation />}
      <Suspense>
        <div className={mergeClasses(hasBanner && !!!hideMenuAndFooter && 'pt-banner', 'min-h-screen')}>{children}</div>
      </Suspense>
      <Suspense>{hideMenuAndFooter ? <></> : <Footer />}</Suspense>
      <Client>
        <GdprCookie />
        <NewsletterPopover />
      </Client>
    </>
  )
}

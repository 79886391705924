'use client'

import mergeClasses from 'core/utils/mergeClasses'

import Button from 'components/Button'

export default function MenuButton({ active, highlight, ...props }: (typeof Button)['arguments']): JSX.Element {
  return (
    <Button
      {...props}
      styleType="link"
      className={mergeClasses(
        '!h-xl items-center',
        active ? (highlight ? 'text-crimson before:border-crimson' : 'text-ink') : 'text-ghost'
      )}
      tracking={
        props.tracking || {
          category: 'navigation',
          label: 'category',
        }
      }
    />
  )
}

import { useSearchParams, useParams } from 'next/navigation'
import { useState } from 'react'

import useI18n from 'core/hooks/useI18n'
import useNavigationLinks from 'core/hooks/useNavigationLinks'
import { orderHistoryUrl } from 'core/routes/accountUrls'
import mergeClasses from 'core/utils/mergeClasses'

import CategoryButton from './CategoryButton'
import MenuBreadcrumbs from './MenuBreadcrumbs'

type NavLink = [string, keyof I18n.Keys]
const menulinks: NavLink[] = [
  [orderHistoryUrl, 'navigation.my-account'],
  ['/faq', 'navigation.faq'],
  ['/stores', 'navigation.stores'],
]

export default function LinksByTab({ onClick }: { onClick?: () => void }): JSX.Element | null {
  const { t } = useI18n()

  const searchParams = Object.fromEntries(useSearchParams()?.entries() || [])
  const params = useParams()
  const query = { ...searchParams, ...params }

  const { gender, category } = (query || {}) as {
    gender?: string
    category?: string
  }

  const [hasNavigated, setHasNavigated] = useState(false)
  const [navState, setNavState] = useState<{ gender?: string | null; category?: string | null }>({ gender, category })
  const { links, parents, dato, sale } = useNavigationLinks({
    gender: navState.gender,
    category: navState.category as string,
  })

  if (!links) return null

  const parent = parents[parents?.length - 1]

  return (
    <div className={'w-full'}>
      {!parent && !!sale && (
        <CategoryButton
          bold
          highlight
          key="sale"
          to={sale.url}
          onClick={onClick}
        >
          {sale.title}
        </CategoryButton>
      )}
      <MenuBreadcrumbs
        large
        gender={navState.gender}
        category={navState.category as string}
        onClick={(slug, isGender) => {
          setNavState(
            isGender
              ? { gender: slug?.replace('/', ''), category: null }
              : { gender: navState.gender, category: slug?.replace('/', '') }
          )
          setHasNavigated(true)
        }}
        withBack
      />
      <div
        className={mergeClasses(
          !!parent && 'animate-slide-in-r-l',
          !!!parent && hasNavigated && 'animate-slide-in-l-r'
        )}
      >
        {links?.map(({ slug, slugFull, title, links }, index) => {
          const props: { hasChildren: boolean; onClick?: () => void; to?: string } = {
            hasChildren: !!links && links?.length > 0,
          }

          if (props.hasChildren) {
            props.onClick = () =>
              void setNavState(
                parents.length === 0 ? { gender: slug, category: null } : { gender: navState.gender, category: slug }
              )
          } else {
            props.onClick = onClick
            props.to = slugFull
          }

          return (
            <CategoryButton
              key={slug + index}
              bold={!navState.gender || parents.length === 0}
              {...props}
            >
              {title}
            </CategoryButton>
          )
        })}
        {parent && !dato && (
          <CategoryButton
            bold={!navState.gender}
            to={parent.slugFull}
            onClick={onClick}
          >
            {`All ${parent.title}`}
          </CategoryButton>
        )}
        {!parent && (
          <>
            {menulinks.map((item, index) => (
              <CategoryButton
                bold
                key={index}
                to={item[0]}
                onClick={onClick}
              >
                {t(item[1])}
              </CategoryButton>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'

export default function useIsMouseWithin({ x, y }: { x?: number; y?: number }): boolean {
  const [isWithin, setIsWithin] = useState(false)

  useEffect(() => {
    function updateMousePosition({ clientX, clientY }: MouseEvent): void {
      if (typeof x !== 'undefined' && clientX < x) {
        setIsWithin(true)
      } else if (typeof y !== 'undefined' && clientY < y) {
        setIsWithin(true)
      } else {
        setIsWithin(false)
      }
    }
    window.addEventListener('mousemove', updateMousePosition)
    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
    }
  }, [])

  return isWithin
}

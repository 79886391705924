import { HTMLAttributes } from 'react'

import mergeClasses from 'core/utils/mergeClasses'

import type { MenuResultType } from 'app/lib/getNavigation'

import MenuButton from './MenuButton'

type sectionType = MenuResultType['menu']

export default function Menu({
  open,
  menuItems,
  onClick,
  ...props
}: {
  open?: boolean
  menuItems: sectionType | null
  onClick?: () => void
} & HTMLAttributes<HTMLDivElement>): JSX.Element | null {
  if (!menuItems) return null

  const columnCount = menuItems?.length || 0

  return (
    <div
      className={mergeClasses(
        'relative w-full flex bg-cotton pointer-events-auto',
        open ? 'timing-in opacity-100 visible translate-y-[0%]' : 'timing-out opacity-0 hidden translate-y-[-105%]'
      )}
      {...props}
    >
      <div className="mx-auto my-0 flex w-full max-w-content px-md md:px-lg">
        <div
          className={mergeClasses(
            'flex items-start justify-between',
            columnCount > 3 ? 'min-w-[65%] lg:min-w-[60%] xl:min-w-[50%]' : 'min-w-[55%] lg:min-w-[45%] xl:min-w-[35%]'
          )}
        >
          {menuItems?.map(({ id, slugFull, title, links, dato }, index) => (
            <div
              className="my-lg flex flex-col"
              key={`${index}-${id}`}
            >
              <div className="px-0 py-xs text-lg font-bold">{title}</div>
              {links?.map(({ title, slugFull }, index) => (
                <MenuButton
                  key={`${slugFull}-${index}`}
                  active
                  onClick={onClick}
                  to={slugFull}
                >
                  {title}
                </MenuButton>
              ))}
              {!dato && links && links?.length > 1 && (
                <MenuButton
                  active
                  onClick={onClick}
                  to={slugFull}
                >
                  All {title}
                </MenuButton>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

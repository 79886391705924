import { animated } from '@react-spring/web'

import useI18n from 'core/hooks/useI18n'
import useNotifications from 'core/hooks/useNotifications'
import useOverlayControls from 'core/hooks/useOverlayControls'
import type { Notification, NotificationErrorContext, NotificationAddedCartContext } from 'core/types/notification'

import Button from 'components/Button'
import Icon from 'components/Icon'
import Image from 'components/Image'

export default function Notification({ id, type, context, style }: Notification & { style: any }): JSX.Element | null {
  const { t } = useI18n()
  const { remove } = useNotifications()
  const { show } = useOverlayControls()

  if (type === 'added-cart') {
    const product = context as NotificationAddedCartContext

    return (
      <animated.li
        className="mb-md flex w-full min-w-[300px] flex-row items-center justify-between rounded-[calc(theme(spacing.xl)+theme(spacing.md))] border border-solid border-ink bg-ghost p-sm"
        style={style}
      >
        <Image
          className="mr-md !h-[calc(theme(spacing.lg)*2)] !w-[calc(theme(spacing.lg)*2)] overflow-hidden rounded-xl"
          src={product.thumbnail}
          sizes={`48px`}
          alt={product.name}
          objectFit="cover"
        />
        <div className="flex grow flex-col justify-between font-bold [&>*]:flex [&>*]:items-center [&>*]:font-normal">
          {product.name}
          <span>
            <Icon
              name="check"
              color="ghost"
              className="mr-xs !h-[calc(theme(spacing.md)+theme(spacing.xs))] !w-[calc(theme(spacing.md)+theme(spacing.xs))] rounded-lg bg-grass"
            />{' '}
            added to cart
          </span>
        </div>
        <Button
          className="mr-sm"
          styleType="icon"
          icon="cart"
          onClick={() => show('cart')}
        >
          {t('navigation.cart')}
        </Button>
      </animated.li>
    )
  }

  if (type === 'error') {
    const error = context as NotificationErrorContext
    return (
      <div>
        <div>{error.message}</div>
        <Button
          className="mr-sm"
          styleType="icon"
          icon="cross"
          onClick={() => remove(id)}
        >
          {t('button.close')}
        </Button>
      </div>
    )
  }

  return null
}

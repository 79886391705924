import { HTMLAttributes, MouseEvent } from 'react'

import { useMenuContext } from 'core/context/MenuProvider'
import useI18n from 'core/hooks/useI18n'

import Button from 'components/Button'

function BannerLink({
  link,
  bg,
  color,
  children,
}: { link?: string | null; bg?: string; color?: string } & HTMLAttributes<HTMLDivElement>): JSX.Element {
  const classNames =
    'flex justify-center items-center h-banner bg-ink text-sm text-ghost [&>span]:animate-infinite-left [&>span]:whitespace-nowrap [&>span]:pr-[150px]'

  if (!link) {
    return (
      <div
        className={classNames}
        style={{ backgroundColor: bg, color: color }}
      >
        {children}
      </div>
    )
  }

  return (
    <Button
      styleType="base"
      className={classNames}
      to={link}
      style={{ backgroundColor: bg, color: color }}
    >
      {children}
    </Button>
  )
}

export default function Banner({}: {}): JSX.Element | null {
  const { t } = useI18n()

  const menuData = useMenuContext()
  const { banner, hasBanner, closeBanner } = menuData
  const content = new Array(10).fill(banner?.value)

  if (!banner || !hasBanner) {
    return null
  }

  return (
    <div className="pointer-events-auto relative z-[2] flex h-banner w-full items-center justify-center">
      <BannerLink
        link={banner.link}
        bg={banner.backgroundColor || ''}
        color={banner.textColor || ''}
      >
        {content.map((value, index) => (
          <span key={index}>{value}</span>
        ))}
      </BannerLink>
      <div
        className={`absolute right-0 top-0 bg-[var(--bg,theme(colors.ink.DEFAULT))] px-md before:absolute before:-left-lg before:flex before:h-full before:w-lg before:bg-transparent before:bg-gradient-to-r before:from-transparent before:to-[var(--bg,theme(colors.ink.DEFAULT))] before:content-['']`}
        style={{ '--bg': banner.backgroundColor || '' } as React.CSSProperties}
      >
        <Button
          styleType="text"
          className="h-banner"
          tracking={{
            category: 'navigation',
            label: 'close-banner',
          }}
          onClick={(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
            event.preventDefault()
            closeBanner()
          }}
          style={{ color: banner.textColor || '' }}
        >
          {t('button.close')}
        </Button>
      </div>
    </div>
  )
}

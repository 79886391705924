import { ApolloLink } from '@apollo/client'
import { captureMessage } from '@sentry/browser'

import { add } from 'core/hooks/useNotifications'
import type { NotificationErrorContext } from 'core/types/notification'
import { collectApolloErrors, readableApolloError } from 'core/utils/graphql'

function getErrorMessage(error: readableApolloError): { message: string } | void {
  if (error.key === 'checkoutLinesAdd') {
    return {
      message: error.message || '',
    }
    //Not enough items in stock
  } else if (error.field === 'quantity' && error?.message?.match(/Could not add items/)) {
    return {
      message: `Sorry, there is not enough stock. Please contact customer support`,
    }
  }
}

const client = new ApolloLink((operation, forward) => {
  operation.setContext({ requestStartedAt: new Date() })
  return forward(operation).map((data) => {
    try {
      const errorMessages = collectApolloErrors(data?.data)
        .map((error) => getErrorMessage(error))
        .filter(Boolean)

      errorMessages.forEach(
        (message, index) =>
          index < 2 &&
          message &&
          add({
            type: 'error',
            context: {
              message: message.message,
            } as NotificationErrorContext,
          })
      )

      if (errorMessages.length > 0) {
        captureMessage(JSON.stringify(errorMessages), 'log')
      }
    } catch (e) {
    } finally {
      return data
    }
  })
})

export default client

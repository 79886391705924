import Logo from 'components/Logo'

export default function Maintenance(): JSX.Element {
  return (
    <div
      className={`fixed flex h-full w-full items-center bg-[url('/images/construction.png')] bg-cover bg-[left_center]`}
    >
      <div className="mx-lg flex max-w-screens-sm flex-col">
        <Logo />
        <h3 className="mt-lg text-center">
          We are currently working on the website.
          <br /> We will be back soon!
        </h3>
      </div>
    </div>
  )
}

'use client'

import { Formik, Form } from 'formik'
import { useRouter, useParams } from 'next/navigation'
import { useState, useEffect, useRef } from 'react'

import useI18n from 'core/hooks/useI18n'
import useSWR from 'core/hooks/useSWR'
import mergeClasses from 'core/utils/mergeClasses'

import { supportColors } from 'components/AdamTheme/theme/colors'
import Button from 'components/Button'
import FormikField from 'components/FormikField'

import Input from './Input'

export default function SearchInput({
  id,
  iconColor,
  align = 'left',
  onActiveChange,
  className,
}: {
  id?: string
  iconColor: keyof typeof supportColors | 'ink' | 'ghost'
  align: 'left' | 'right'
  onActiveChange?: (active: boolean) => void
  className?: string
}): JSX.Element {
  const { t } = useI18n()
  const { push } = useRouter()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [active, setActive] = useState(false)
  const [loadSearch, setLoadSearch] = useState(false)
  const [selectedId, setSelectedId] = useState<number>(-1)
  const [queryState, setQueryState] = useState<null | string>(null)

  const { query } = useParams() || {}

  const { data, isLoading } = useSWR<string[]>(
    loadSearch ? `/api/search/autocomplete?query=${queryState || ' '}` : null
  )

  useEffect(() => {
    onActiveChange && onActiveChange(active)
    setLoadSearch(active)
  }, [active, onActiveChange])

  useEffect(() => {
    setSelectedId(-1)
  }, [queryState])

  function clear(): void {
    setSelectedId(-1)
    setActive(false)
  }

  const isReverse = align === 'right'

  return (
    <Formik
      initialValues={{ query }}
      onSubmit={(values): void => {
        if (values.query) {
          push(`/search?query=${values.query}`)
        }
      }}
      validate={(values): void => {
        if (values.query) {
          setQueryState(values.query as string)
        }
      }}
      enableReinitialize={false}
    >
      {(): JSX.Element => {
        return (
          <div
            className={mergeClasses(
              'mt-[20px] rounded-lg h-fit lg:h-auto',
              active && 'focus-lg border border-solid border-ink bg-ghost w-full !h-fit',
              className
            )}
            id={id}
            ref={containerRef}
          >
            <Form
              className={mergeClasses(
                'flex relative flex-grow items-center p-0 justify-start h-full',
                isReverse ? 'flex-row-reverse' : 'flex-row'
              )}
            >
              <Button
                icon="search"
                styleType="icon"
                className={mergeClasses(
                  '!absolute z-1 disabled:pointer-events-none disabled:text-ink',
                  isReverse && active ? '!left-[0%]' : '!left-[calc(100%-34px)]'
                )}
                color={iconColor}
                onClick={() => setActive(true)}
                disabled={active}
                tracking={{
                  category: 'search',
                  label: 'open',
                }}
              >
                {t('button.search')}
              </Button>
              <FormikField
                className={mergeClasses(
                  '-left-2xs h-lg right-0 flex !mb-0 absolute !p-0',
                  active ? 'visible' : 'invisible',
                  isReverse ? 'flex-row-reverse' : 'flex-row'
                )}
                disabled={!active}
                active={active}
                component={Input}
                name="query"
                placeholder={t('search.placeholder')}
                onBlur={(event: MouseEvent) => {
                  const isChild = event.relatedTarget && containerRef.current?.contains(event.relatedTarget as Node)

                  if (!isChild) {
                    clear()
                  }
                }}
                onKeyDown={(event: KeyboardEvent) => {
                  if (!['ArrowUp', 'ArrowDown', 'Tab', 'Enter'].includes(event.code)) {
                    return
                  }

                  const hasShift = event.shiftKey
                  const total = data?.length || 0
                  let nextId = -1

                  event?.preventDefault()

                  if (['ArrowDown', 'Tab'].includes(event.code) && !hasShift) {
                    nextId = selectedId + 1 === total ? 0 : selectedId + 1
                  }
                  if (event.code === 'ArrowUp' || (hasShift && event.code === 'Tab')) {
                    nextId = selectedId === 0 ? total - 1 : selectedId - 1
                  }
                  if (event.code === 'Enter') {
                    if (selectedId < 0) {
                      push(`/search?query=${queryState}`)
                    } else {
                      push(`/search?query=${data?.[selectedId]}`)
                    }

                    setActive(false)
                    return
                  }

                  setSelectedId(nextId)
                }}
              />
              <Button
                styleType="icon"
                icon="cross"
                className={mergeClasses(
                  'opacity-0 invisible duration-0 transition timing-in-out-cubic absolute right-2xs z-1',
                  active && 'duration-[150ms] visible flex opacity-100'
                )}
                loading={isLoading}
                disabled={!active}
                onClick={() => setActive(false)}
                tracking={{
                  category: 'search',
                  label: 'close',
                }}
              >
                {t('button.close')}
              </Button>
            </Form>
            {active && data && (
              <ul className="emtpy:hidden flex flex-col px-md py-sm ">
                {data.map((value, index) => (
                  <li
                    key={index}
                    className="mb-sm ml-0"
                  >
                    <Button
                      styleType="link"
                      className={mergeClasses(
                        'hover:before:translate-y-[10px] !ml-0 text-lg inline-flex',
                        index === selectedId &&
                          'before:border-b-[3px] before:opacity-100 before:translate-y-[10px] before:border-cornflower '
                      )}
                      to={`/search?query=${value}`}
                      onClick={clear}
                    >
                      {value}
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )
      }}
    </Formik>
  )
}

'use client'

import OverlayProvider from './OverlayProvider'
import RouteChangeProvider from './RouteChangeProvider'
import UIStateProvider from './UIStateProvider'

const providers = [UIStateProvider, OverlayProvider, RouteChangeProvider]

export default function Providers({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <>
      {providers.reduce((Reduced, Provider) => {
        return <Provider>{Reduced}</Provider>
      }, children)}
    </>
  )
}

'use client'

import { usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'
import algolia from 'search-insights'

import useSWR from 'core/hooks/useSWR'

import 'globalthis/polyfill'

export default function InitScripts(): null {
  const pathname = usePathname()
  const { refresh } = useRouter()
  const isCheckout = pathname?.includes('checkout')
  const { data: { version } = {} } = useSWR<{ version: string }>('/api/version')

  useEffect(() => {
    try {
      algolia('init', {
        appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
        apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
        useCookie: true,
      })
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (version && !isCheckout && version !== process.env.NEXT_PUBLIC_RELEASE) {
      refresh()
    }
  }, [version, isCheckout])

  return null
}

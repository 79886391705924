import { animated, useTransition } from '@react-spring/web'

import mergeClasses from 'core/utils/mergeClasses'

import transitions from 'components/AdamTheme/theme/transitions'
import LocaleSelector from 'components/LocaleSelector'
import Scroll from 'components/Scroll'

import LinksByTab from './LinksByTab'

export default function NavigationMobile({
  open,
  onClick,
  hasBanner,
}: {
  open: boolean
  onClick?: () => void
  hasBanner?: boolean
}): JSX.Element {
  const rightTransition = useTransition(open, {
    leave: {
      transform: `scale(1) rotateX(0) translate(105%, 0%)`,
    },
    enter: {
      transform: `scale(1) rotateX(0) translate(0%, 0%)`,
    },
    from: {
      transform: `scale(1) rotateX(0) translate(105%, 0%)`,
    },
    config: transitions.springMenu,
  })
  return (
    <>
      <div
        className={mergeClasses(
          'absolute inset-0 transition timing-in-out-quad duration 230 bg-gradient-to-r from-ink/[0.6] to-[90%] to-ink',
          open ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        )}
        onClick={() => open && onClick && onClick()}
      />
      {rightTransition(
        (style, open) =>
          open && (
            <animated.div
              className={mergeClasses(
                'absolute top-0 right-0 bottom-0 flex flex-col w-full max-w-screens-sm overflow-y-scroll justify-between bg-ghost pointer-events-auto',
                hasBanner ? `pt-[calc(theme(height.nav)+30px)]` : 'pt-nav'
              )}
              style={style}
            >
              <Scroll className="flex flex-col justify-between px-md py-sm">
                <LinksByTab onClick={onClick} />
                <LocaleSelector className="mt-xl" />
              </Scroll>
            </animated.div>
          )
      )}
    </>
  )
}

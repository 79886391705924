import colors from 'components/AdamTheme/theme/colors'
import sizes from 'components/AdamTheme/theme/sizes'

import './logo.css'

export default function LogoLoader({ color }: { color?: keyof typeof colors }): JSX.Element {
  return (
    <div
      className="logo-path"
      style={
        {
          '--stroke-color': color ? colors[color] : colors.ink,
        } as React.CSSProperties
      }
    >
      <svg
        width={sizes.spacingXl}
        height={sizes.spacingXl}
        viewBox="0 0 480 494"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M409.52 283.61L240.37 282.07C208.72 280.96 179.8 281.69 155.95 282.81C131.08 283.98 114.46 288.9 107.48 290.57C61.38 301.6 40.75 335.83 40.75 335.83C18.98 366.95 20.38 414.54 48.4 442.57C79.17 473.34 125.99 476.49 158.37 448.42C159.22 447.68 160.03 446.89 160.94 446.22C178.36 433.4 194.12 411.2 232.28 280.73L318.65 25.75L453.58 407.59"
          stroke={colors.ink}
          strokeWidth="51.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

import { useEffect } from 'react'

import session from 'core/session'

declare global {
  interface Window {
    intercomSettings: Record<string, string>
    Intercom: (...args: any) => void
  }
}

const updateMetadata = (): void => {
  if (window.intercomSettings) {
    window.intercomSettings = { ...window.intercomSettings }
    window.intercomSettings['channel'] = session.channel
    window.intercomSettings['checkout_id'] = session.cartId
    window.intercomSettings['auth_token'] = session.storefrontToken as string
    if (window.Intercom) {
      window.Intercom('update')
    }
  }
}

export const useSyncIntercomSessionAttributes = (): void => {
  useEffect(() => {
    updateMetadata()
  })
}

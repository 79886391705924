import useNavigationLinks from 'core/hooks/useNavigationLinks'

import Breadcrumbs from 'components/Breadcrumbs'

export default function MenuBreadcrumbs({
  gender,
  category,
  onClick,
  large,
  withBack,
}: {
  gender?: string | null
  category?: string | null
  onClick?: (slug: string | null, isGender: boolean) => void
  large?: boolean
  withBack?: boolean
}): JSX.Element {
  const { parents } = useNavigationLinks({ gender, category })

  if (!parents) {
    return (
      <Breadcrumbs
        category={'teset'}
        ancestors={[]}
        onClick={onClick}
        large={large}
        withBack={withBack}
      />
    )
  }

  const current = parents[parents.length - 1]
  const ancestors = parents.slice(0, -1).map(({ title, slugFull }) => ({ name: title, url: slugFull }))

  return (
    <Breadcrumbs
      category={current?.title}
      ancestors={ancestors}
      onClick={onClick}
      large={large}
      withBack={withBack}
    />
  )
}

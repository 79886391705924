import reactStringReplace from 'react-string-replace'

import useI18n from 'core/hooks/useI18n'
import useIsSpeedCurve from 'core/hooks/useIsSpeedCurve'
import useSession from 'core/hooks/useSession'
import session from 'core/session'

import Button from 'components/Button'

export default function GdprCookie(): JSX.Element | null {
  const { t } = useI18n()
  const { gdprConsent } = useSession()
  const isSpeedCurve = useIsSpeedCurve()

  if (gdprConsent || isSpeedCurve) {
    return null
  }

  const copy = reactStringReplace(t('gdpr.cookie'), /\{\{(.*)\}\}/g, (match, index) => (
    <Button
      key={index}
      styleType="text"
      to="/privacy-policy"
    >
      {match}
    </Button>
  ))

  return (
    <div className="fixed bottom-0 left-1/2 z-1 flex w-full -translate-x-1/2 flex-row-reverse items-center bg-ghost p-md md:max-w-form md:flex-row md:rounded-t-md">
      <span>{copy}</span>
      <Button
        className="mr-md md:mr-sm"
        icon="check"
        styleType="icon"
        inverted
        color="grass"
        onClick={() => {
          session.gdprConsent = true
        }}
      >
        Agree
      </Button>
    </div>
  )
}

import { useSearchParams } from 'next/navigation'
import { useState, useEffect } from 'react'

import { useMenuContext } from 'core/context/MenuProvider'

import type { MenuBlockType } from 'app/lib/getNavigation'

export default function useNavigationLinks({
  gender: _gender,
  category: _category,
}: {
  gender?: string | null
  category?: string | null
}): {
  parents: MenuBlockType[]
  links: MenuBlockType[] | MenuBlockType['links']
  query: { gender?: string | null; category?: string | null }
  sale?: { title: string; url: string } | null
  loading: boolean
  dato: boolean
} {
  const data = useMenuContext()
  const parents: MenuBlockType[] = []
  const _query = Object.fromEntries(useSearchParams()?.entries() || [])

  const { gender: routerGender, category: routerCategory } = (_query || {}) as {
    gender?: string
    category?: string
  }

  let dato = false

  const [query, setQuery] = useState({
    gender: typeof _gender === 'undefined' ? routerGender : _gender,
    category: typeof _category === 'undefined' ? routerCategory : _category,
  })

  useEffect(() => {
    setQuery({ gender: routerGender, category: routerCategory })
  }, [routerGender, routerCategory])

  useEffect(() => {
    setQuery({ gender: _gender, category: _category })
  }, [_gender, _category])

  if (!data) return { parents: [], links: [], query, loading: true, dato: false }

  const menu = [...data.shop, ...data.menu]

  let links: MenuBlockType[] | MenuBlockType['links'] | MenuBlockType['links'][number]['links']

  if (!query.gender) {
    links = menu
  } else {
    if (!query.category) {
      const parent = menu.find(({ slug }) => slug === query.gender)
      links = parent?.links || []
      if (parent) {
        parents.push(parent)
      }
      dato = !!parent?.dato
    } else {
      const _gender = menu.find(({ slug }) => slug === query.gender)
      if (_gender) {
        parents.push(_gender)
      }
      dato = !!_gender?.dato
      links = _gender?.links?.map((props) => ({ ...props })) || []
    }
  }

  return {
    parents,
    links,
    query,
    dato,
    sale: data.sale,
    loading: false,
  }
}

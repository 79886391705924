'use client'

import { loadIntercom, showIntercomWindow } from 'next-intercom'
import { useState } from 'react'

import Button from 'components/Button'

import { default as Icon } from './assets/intercomIcon.svg'

export default function Intercom(): JSX.Element | null {
  const [isIntercomLoading, setIsIntercomLoading] = useState(false)

  return (
    <Button
      styleType="icon"
      customIcon={
        <Icon
          viewBox={`0 0 ${28} ${32}`}
          width={28}
          height={32}
          className="h-lg w-lg !fill-ghost"
        />
      }
      onClick={async () => {
        setIsIntercomLoading(true)

        await loadIntercom({
          appId: process.env.NEXT_PUBLIC_INTERCOM,
          ssr: false,
          initWindow: true,
        })
        showIntercomWindow()
      }}
      loading={isIntercomLoading}
      className="!fixed bottom-[20px] right-[20px] z-[1000] !h-[calc(theme(spacing.lg)*2)] !w-[calc(theme(spacing.lg)*2)] rounded-xl !bg-ink transition-[transform] hover:scale-[1.1] "
    >
      Service
    </Button>
  )
}

import { setContext } from '@apollo/client/link/context'

export default function authHeaders(token: string): ReturnType<typeof setContext> {
  return setContext((_, context) => {
    let headers = {
      ...context.headers,
      'X-Shopify-Storefront-Access-Token': token,
    }

    return {
      ...context,
      headers,
    }
  })
}

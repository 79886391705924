import(/* webpackMode: "eager" */ "/vercel/path0/components/AdamTheme/main.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Intercom/Intercom.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Layout/InitScripts.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Layout/Layout.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Notifications/Notifications.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/OverlayManager/OverlayManager.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/PageLoader/PageLoader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/core/context/ApolloProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/core/context/I18nProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/core/context/MenuProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/core/context/Providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/core/context/ShopifyProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/core/context/TimeProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"components/AdamTheme/AdamTheme.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--walsheim\",\"src\":[{\"path\":\"./fonts/GT-Walsheim-Pro-Regular-subset.woff\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/GT-Walsheim-Pro-Bold-subset.woff\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"walsheim\"}")
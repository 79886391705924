'use client'

import { useRef, useState, useEffect } from 'react'

import { useMenuContext } from 'core/context/MenuProvider'
import useDisableBodyScroll from 'core/hooks/useDisableBodyScroll'
import useHideIntercom from 'core/hooks/useHideIntercom'
import useIsInViewport from 'core/hooks/useIsInViewport'
import useIsMouseWithin from 'core/hooks/useIsMouseWithin'
import useIsScrolledPast from 'core/hooks/useIsScrolledPast'
import useUIState from 'core/hooks/useUIState'
import mergeClasses from 'core/utils/mergeClasses'

import type { MenuResultType } from 'app/lib/getNavigation'

import Banner from './Banner'
import Menu from './Menu'
import MobileMenu from './MobileMenu'
import NavigationHolder from './NavigationHolder'
import NavigationLinks from './NavigationLinks'

type sectionType = MenuResultType['menu']

export default function Navigation(): JSX.Element {
  const menuData = useMenuContext()

  const isLargeMobile = useIsInViewport('sm')
  const isTablet = useIsInViewport('md')
  const scrolledPastThreshold = useIsScrolledPast({ threshold: 100 })
  const mouseIsClose = useIsMouseWithin({ y: 200 })

  const { menu } = useUIState()

  const timer = useRef<NodeJS.Timeout | null>(null)
  const timerMenu = useRef<NodeJS.Timeout | null>(null)
  const timerNavigation = useRef<NodeJS.Timeout | null>(null)
  const timerClick = useRef<NodeJS.Timeout | null>(null)

  const [menuItems, setMenuItems] = useState<sectionType | null>(null)
  const [isBlocked, setBlocked] = useState(false)
  const [isMobileNavOpen, setMobile] = useState(false)
  const [active, setActive] = useState(!menu.transparent)
  const [linksActive, setLinksActive] = useState(false)
  const [menuHover, setMenuHover] = useState(false)

  const isMenuOpen = (menuHover || linksActive) && isTablet
  const hasBanner = !!menuData.hasBanner

  const hasOpaqueBackground = active || (isLargeMobile && isMobileNavOpen)

  function blockNavigation(): void {
    setBlocked(true)
    timerClick.current = setTimeout(() => setBlocked(false), 300)
  }

  useDisableBodyScroll(isMobileNavOpen)
  useHideIntercom(isMobileNavOpen)

  useEffect(() => {
    if (isBlocked) {
      return
    }

    const state = linksActive || menuHover || !menu.transparent || scrolledPastThreshold || (isTablet && mouseIsClose)

    if (!state && state !== active) {
      timerNavigation.current = setTimeout(() => setActive(state), 200)
    } else {
      setActive(state)
      timerNavigation.current && clearTimeout(timerNavigation.current)
    }
  }, [linksActive, menuHover, menu, scrolledPastThreshold, mouseIsClose, isMobileNavOpen])

  return (
    <nav className="pointer-events-none fixed inset-0 z-navigation m-auto flex w-full flex-col">
      <Banner />
      <NavigationHolder>
        <div className="pointer-events-none absolute inset-0 h-nav bg-gradient-to-t from-ink/[0] to-ink/[0.25]" />
        <div className="pointer-events-auto relative z-1">
          <div
            className={mergeClasses(
              'pointer-events-none absolute inset-0',
              hasOpaqueBackground ? 'bg-ghost' : 'bg-transparent',
              hasOpaqueBackground ? 'translate-y-[0%]' : 'translate-y-[-100%]',
              hasOpaqueBackground ? 'transition-all duration-200 timing-in' : 'transition-all timing-out duration-300'
            )}
          />
          <NavigationLinks
            mobileMenuOpen={isMobileNavOpen}
            onMobileMenuToggle={setMobile}
            onMenuSelect={(section) => {
              setMenuItems(section)
            }}
            onActiveChange={setLinksActive}
            active={active}
          />
        </div>
      </NavigationHolder>
      {!isTablet ? (
        <MobileMenu
          hasBanner={!!hasBanner}
          open={isMobileNavOpen}
          onClick={() => setMobile(false)}
        />
      ) : (
        <Menu
          open={isMenuOpen}
          menuItems={menuItems}
          onClick={() => {
            timer.current && clearTimeout(timer.current)
            timerMenu.current && clearTimeout(timerMenu.current)
            setMenuHover(false)
            setLinksActive(false)
            blockNavigation()
          }}
          onMouseEnter={() => !isBlocked && setMenuHover(true)}
          onMouseOver={() => {
            !isBlocked && setMenuHover(true)
            timerMenu.current && clearTimeout(timerMenu.current)
          }}
          onMouseLeave={() => {
            timerMenu.current = setTimeout(() => setMenuHover(false), 200)
          }}
        />
      )}
    </nav>
  )
}

import { useEffect, useState } from 'react'

export default function useIsSpeedCurve(): boolean {
  const [isSpeedCurve, setSpeedCurve] = useState(true)

  useEffect(() => {
    setSpeedCurve(navigator.userAgent.includes('PTST/SpeedCurve'))
  }, [])

  return isSpeedCurve
}

import mergeClasses from 'core/utils/mergeClasses'

import Button from 'components/Button'
import Icon from 'components/Icon'

export default function CategoryButton({
  bold,
  children,
  highlight,
  hasChildren,
  ...props
}: { bold?: boolean; hasChildren?: boolean; highlight?: boolean; children: string } & PropsOf<
  typeof Button
>): JSX.Element {
  return (
    <Button
      styleType="base"
      className={mergeClasses(
        'my-sm leading-none relative flex w-full text-2xl',
        bold && 'font-bold',
        highlight && 'text-crimson'
      )}
      {...props}
    >
      {children}
      {hasChildren && (
        <Icon
          className="!absolute right-0"
          name="chevronRight"
        />
      )}
    </Button>
  )
}

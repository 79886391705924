'use client'

import { useEffect, useRef } from 'react'

import { InputProps } from 'core/types/elements'
import mergeClasses from 'core/utils/mergeClasses'

export default function Input({
  active,
  onBlur,
  onFocus,
  onChange,
  value,
  disabled,
  id,
  name,
  isInvalid: _,
  className,
  ...props
}: { active?: boolean } & InputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    active && setTimeout(() => inputRef.current?.focus(), 100)
  }, [active])

  return (
    <input
      id={id}
      name={name}
      disabled={disabled}
      ref={inputRef}
      value={value || ''}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      autoComplete="off"
      className={mergeClasses(
        'text-xl leading-none text-ink h-full rounded-2xl border border-solid border-transparent transition-all timing-in-out-cubic duration-150 bg-transparent pl-xl outline-none',
        active ? 'w-full' : 'w-xl',
        className
      )}
      {...props}
      type="text"
    />
  )
}

import { useCart } from 'core/context/ShopifyProvider'
import useI18n from 'core/hooks/useI18n'

import Button from 'components/Button'

export default function CartButton(props: (typeof Button)['arguments']): JSX.Element {
  const { t } = useI18n()
  const { lines } = useCart()

  const cartQuantity = lines?.reduce((sum, line) => {
    sum += line?.quantity || 0
    return sum
  }, 0)

  return (
    <Button
      {...props}
      iconNotifications={cartQuantity}
      icon="cart"
      styleType="icon"
      tracking={{
        category: 'navigation',
        label: 'cart',
      }}
    >
      {t('navigation.cart')}
    </Button>
  )
}

'use client'

import { useTransition } from '@react-spring/web'
import { useEffect, useRef } from 'react'

import useNotifications from 'core/hooks/useNotifications'
import type { NotificationErrorContext } from 'core/types/notification'

import transitions from 'components/AdamTheme/theme/transitions'
import Client from 'components/Client'

import Notification from './Notification'

const notificationTimeout = 4000
export default function Notifications(): JSX.Element | null {
  const timer = useRef<NodeJS.Timeout | null>(null)
  const { notifications, remove } = useNotifications()

  useEffect(() => {
    if (notifications.length > 0) {
      timer.current && clearTimeout(timer.current)
      const last = notifications[0]?.id
      timer.current = setTimeout(() => last && remove(last), notificationTimeout)
    }

    return () => {
      timer.current && clearTimeout(timer.current)
    }
  }, [notifications])

  const notificationsTransition = useTransition(notifications, {
    position: 'absolute',
    leave: {
      transform: `scale(1) rotateX(0) translate(120%, 0%)`,
    },
    enter: {
      transform: `scale(1) rotateX(0) translate(0%, 0%)`,
    },
    from: {
      transform: `scale(1) rotateX(0) translate(120%, 0%)`,
    },
    config: { ...transitions.springMenu, mass: 1.5 },
    exitBeforeEnter: true,
  })

  return (
    <Client>
      <ul className="fixed right-0 top-[calc(theme(height.nav)+theme(spacing.md))] z-notifications flex flex-col-reverse px-md ">
        {notificationsTransition(
          (style, notification) =>
            notifications && (
              <Notification
                id={notification.id}
                type={notification.type}
                context={notification.context as NotificationErrorContext}
                style={style}
              />
            )
        )}
      </ul>
    </Client>
  )
}

import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client'
import { HttpLink } from '@apollo/client/link/http'
import { RetryLink } from '@apollo/client/link/retry'

import session from 'core/session'

import authHeaders from './authHeaders'
import cache from './cache'
import errorNotificationsLink from './links/errorNotifications'
// import tokenRefresh from './links/tokenRefresh'

const { RELEASE } = process.env

export function getApolloClient({ token, uri }: { token: string; uri: string }): typeof client {
  const links: ApolloLink[] = []

  links.push(authHeaders(token))

  // links.push(tokenRefresh(refreshToken))

  links.push(errorNotificationsLink)
  links.push(new RetryLink())
  links.push(
    new HttpLink({
      uri,
    })
  )

  const client = new ApolloClient({
    cache,
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from(links),
  })

  return client
}

async function resetStore(apolloClient: ApolloClient<NormalizedCacheObject>): Promise<void> {
  const currentHash = session?.buildHash

  const HASH = RELEASE || ''

  if (currentHash == null) {
    session.buildHash = HASH
  }

  if (HASH !== currentHash && currentHash != null) {
    await apolloClient.resetStore()
    session.buildHash = HASH
  }
}

function initializeApollo(uri: string): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = getApolloClient({ uri, token: process.env.STOREFRONT_API_TOKEN as string })

  if (_apolloClient) resetStore(_apolloClient)

  return _apolloClient
}

export default initializeApollo(
  `https://${process.env.NEXT_PUBLIC_SHOPIFY_SHOP}/api/${process.env.NEXT_PUBLIC_SHOPIFY_VERSION}/graphql.json`
)

'use client'
import { useState, useRef, useEffect } from 'react'

import { useMenuContext } from 'core/context/MenuProvider'
import useCookieHide from 'core/hooks/useCookieHide'
import useDisableBodyScroll from 'core/hooks/useDisableBodyScroll'
import useI18n from 'core/hooks/useI18n'
import useIsSpeedCurve from 'core/hooks/useIsSpeedCurve'
import useOnDocumentKey from 'core/hooks/useOnDocumentKey'
import useSession from 'core/hooks/useSession'
import mergeClasses from 'core/utils/mergeClasses'
import stringToBoolean from 'core/utils/stringToBoolean'

import Button from 'components/Button'
import DatoText from 'components/DatoPage/blocks/components/DatoText'
import Media from 'components/DatoPage/blocks/components/Media'
import SubscribeAndFollow from 'components/SubscribeAndFollow'

export default function NewsletterPopover(): JSX.Element {
  const isB2B = stringToBoolean(process.env.NEXT_PUBLIC_B2B)
  const { t } = useI18n()
  const menuData = useMenuContext()
  const [active, setActive] = useState(false)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [subscribeClosed, closeSubscribe] = useCookieHide('newsletter')
  const isSpeedCurve = useIsSpeedCurve()

  const { subscribed } = useSession()
  const disabled = subscribed || subscribeClosed

  const hasCustomNewsletter = !!menuData.newsletter
  const { body, background } = menuData.newsletter || {}

  function close(): void {
    setActive(false)
    closeSubscribe(true)
  }

  function onSubscribe(): void {
    timer.current = setTimeout(() => setActive(false), 1500)
  }

  useDisableBodyScroll(active)
  useOnDocumentKey('Escape', close, {
    active,
    scope: [active],
  })

  useEffect(() => {
    if (!disabled && !isSpeedCurve && !isB2B) {
      timer.current = setTimeout(() => setActive(true), 5000)
    }
    return () => {
      timer.current && clearTimeout(timer.current)
    }
  }, [disabled, isSpeedCurve, isB2B])

  return (
    <div
      className={mergeClasses(
        'fixed inset-0 bg-ink/[0.6] z-overlay-manager justify-center items-center hidden opacity-0 pointer-events-none',
        active && 'animate-fade-in pointer-events-auto flex'
      )}
    >
      <div
        className={mergeClasses(
          'w-full max-w-input m-md translate-x-full opacity-0 bg-ghost rounded-md overflow-hidden shadow-2xl',
          active && 'animate-slide-in'
        )}
      >
        {hasCustomNewsletter ? (
          <>
            <div className="relative min-h-[300px] p-md">
              {background && (
                <Media
                  className="absolute left-0 top-0 size-full"
                  {...background}
                />
              )}
            </div>
            <div className="p-lg pb-0">
              <DatoText>{body?.value}</DatoText>
            </div>
            <SubscribeAndFollow
              trackingCategory="popup"
              onSubscribe={onSubscribe}
              className="pt-0"
            />
          </>
        ) : (
          <SubscribeAndFollow
            title={t('subscribe.title')}
            description={t('subscribe.description')}
            image={'/icons/pool.svg'}
            trackingCategory="popup"
            onSubscribe={onSubscribe}
          />
        )}
        <Button
          styleType="icon"
          icon="cross"
          color={hasCustomNewsletter ? 'ghost' : 'ink'}
          onClick={close}
          className="!absolute right-sm top-sm"
        >
          {t('button.close')}
        </Button>
      </div>
    </div>
  )
}

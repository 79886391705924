'use client'

import { useEffect, useState, useRef } from 'react'

import useIsRouteChanging from 'core/hooks/useIsRouteChanging'

import LogoLoader from './LogoLoader'

export default function PageLoader(): JSX.Element {
  const { loading: isLoading } = useIsRouteChanging()
  const timeout = 400
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const state = isLoading
    if (state && state !== loading) {
      timer.current = setTimeout(() => setLoading(state), timeout)
    } else {
      setLoading(state)
      timer.current && clearTimeout(timer.current)
    }

    return () => {
      timer.current && clearTimeout(timer.current)
    }
  }, [isLoading])

  return (
    <>
      {loading && (
        <div className="fixed inset-0 z-loader flex grow items-center justify-center bg-ghost/[0.5]">
          <LogoLoader />
        </div>
      )}
    </>
  )
}

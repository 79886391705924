// TODO: refactor this out by utilizing Formik

export type readableApolloError = {
  key?: string
  message?: string
  field?: string
  code?: string | null
}

type apolloError = {
  code?: string | null
  message?: string | null
  field?: string | null
}

export function collectApolloErrors<Tobj extends { [key: string]: { errors?: apolloError[] } | null }>(
  data?: Tobj | null
): readableApolloError[] {
  const errors: readableApolloError[] = []

  data &&
    Object.keys(data).forEach((key) => {
      const payload = data[key]

      if (payload && payload.errors) {
        payload.errors.forEach((error) => {
          errors.push({
            key,
            ...(error as readableApolloError),
          })
        })
      }
    })

  return errors
}

import { useState, useEffect, useRef } from 'react'

import useIsRouteChanging from 'core/hooks/useIsRouteChanging'
import useOnScroll from 'core/hooks/useOnScroll'

export default function useOnScrollPushElement(
  el: HTMLDivElement | null,
  ref: HTMLDivElement | null,
  scope: any[] = [],
  movePastOwnHeight = false
): number {
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const [translate, setTranslate] = useState(0)
  const [height, setHeight] = useState(0)
  const [threshold, setThreshold] = useState(0)
  const { loading } = useIsRouteChanging()

  function calculateBounding(): void {
    const height = el?.getBoundingClientRect().height || 0
    const offset = el?.offsetTop || 0
    const threshold = ref?.offsetTop || 0

    setHeight(height + offset)
    setThreshold(threshold)
  }

  useOnScroll(
    () => {
      const newTranslate = Math.max(0, window.pageYOffset + height - threshold)
      setTranslate(movePastOwnHeight ? newTranslate : newTranslate > height ? height : newTranslate)
    },
    {
      scope: [el, ref, height, threshold, loading],
      throttleRate: -1,
    }
  )

  useEffect(() => {
    calculateBounding()
    timeout.current = setTimeout(calculateBounding, 500)
    window.addEventListener('resize', calculateBounding)

    return () => {
      window.removeEventListener('resize', calculateBounding)
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [el, ref, loading, ...scope])

  return !!ref ? translate : 0
}

import { useRef, useState } from 'react'

import { useMenuContext } from 'core/context/MenuProvider'
import useI18n from 'core/hooks/useI18n'
import useIsInViewport from 'core/hooks/useIsInViewport'
import useOverlayControls from 'core/hooks/useOverlayControls'
import { orderHistoryUrl } from 'core/routes/accountUrls'

import type { MenuResultType } from 'app/lib/getNavigation'

import Button from 'components/Button'
import Logo from 'components/Logo'
import SearchInput from 'components/SearchInput'

import CartButton from './CartButton'
import MenuButton from './MenuButton'

import type { colorType } from 'components/AdamTheme/theme/colors'

type sectionType = MenuResultType['menu']

// TODO: set links inactive once blur on search and mouse is out of range
export default function NavigationLinks({
  active,
  mobileMenuOpen,
  onMenuSelect,
  onMobileMenuToggle,
  onActiveChange,
}: {
  active: boolean
  mobileMenuOpen: boolean
  onMenuSelect: (section: sectionType) => void
  onMobileMenuToggle: (active: boolean) => void
  onActiveChange: (active: boolean) => void
}): JSX.Element {
  const { t } = useI18n()
  const { show } = useOverlayControls()
  const isTablet = useIsInViewport('md')
  const menuData = useMenuContext()

  const [_, setBlocked] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)

  const timerClick = useRef<NodeJS.Timeout | null>(null)
  const timer = useRef<NodeJS.Timeout | null>(null)

  const iconColor: colorType = active || mobileMenuOpen ? 'ink' : 'ghost'

  function blockNavigation(): void {
    setBlocked(true)
    timerClick.current = setTimeout(() => setBlocked(false), 300)
  }

  function clearTimer(): void {
    timer.current && clearTimeout(timer.current)
  }

  const liClass = 'no-select cursor-pointer flex items-center mr-lg'

  return (
    <div
      className="relative m-auto flex h-nav w-full max-w-content justify-between px-md md:px-lg"
      onMouseOver={clearTimer}
      onMouseLeave={() =>
        (timer.current = setTimeout(() => {
          onActiveChange(activeSearch)
        }, 200))
      }
    >
      <ul className="z-1 hidden md:flex ">
        <li
          key="shop"
          className={liClass}
        >
          <MenuButton
            tracking={{
              category: 'navigation',
              label: 'shop',
            }}
            active={active}
            onClick={() => {
              clearTimer()

              onActiveChange(false)
              blockNavigation()
            }}
            to={'/search'}
            onMouseOver={() => {
              clearTimer()

              onMenuSelect(menuData?.shop || null)
              onActiveChange(true)
            }}
          >
            {t('navigation.shop')}
          </MenuButton>
        </li>
        {menuData?.menu
          ?.filter(({ dato, mobile }) => !dato || (dato && !mobile))
          ?.map((section) => {
            const { id, slugFull, title } = section
            const onClick = (): void => {
              clearTimer()
              onActiveChange(false)
              blockNavigation()
            }

            return (
              <li
                key={id}
                className={liClass}
              >
                <MenuButton
                  tracking={{
                    category: 'navigation',
                    label: 'dato',
                  }}
                  active={active}
                  onClick={onClick}
                  to={slugFull}
                  onMouseOver={() => {
                    clearTimer()

                    onMenuSelect([section])
                    onActiveChange(true)
                  }}
                >
                  {title}
                </MenuButton>
              </li>
            )
          })}
        {!!menuData?.sale && (
          <li
            key="sale"
            className={liClass}
          >
            <MenuButton
              highlight
              tracking={{
                category: 'navigation',
                label: 'dato',
              }}
              active={active}
              to={menuData?.sale.url}
            >
              {menuData?.sale.title}
            </MenuButton>
          </li>
        )}
      </ul>
      <SearchInput
        className="md:hidden"
        align="left"
        onActiveChange={setActiveSearch}
        iconColor={iconColor}
      />

      <div className="pointer-events-none absolute inset-0 flex content-center justify-center">
        {(!activeSearch || isTablet) && (
          <Logo
            color={iconColor}
            onClick={() => onMobileMenuToggle(false)}
          />
        )}
      </div>
      <div className="relative flex items-center">
        <div className="relative hidden h-full w-[25vw] max-w-[300px] md:flex">
          <SearchInput
            align="right"
            onActiveChange={setActiveSearch}
            iconColor={iconColor}
            className="absolute right-0"
          />
        </div>
        <Button
          className="ml-md"
          to={orderHistoryUrl}
          color={iconColor}
          icon="user"
          styleType="icon"
          tracking={{
            category: 'navigation',
            label: 'account',
          }}
        >
          {t('navigation.my-account')}
        </Button>

        <CartButton
          iconFlat={!active}
          className="ml-md"
          color={iconColor}
          onClick={() => show('cart')}
        />
        <Button
          styleType="icon"
          className="ml-md md:hidden"
          color={iconColor}
          onClick={() => onMobileMenuToggle(!mobileMenuOpen)}
          icon={mobileMenuOpen ? 'cross' : 'menu'}
          loading={!!!menuData}
          tracking={{
            category: 'navigation',
            label: mobileMenuOpen ? 'menu-close' : 'menu',
          }}
        >
          {t('navigation.menu')}
        </Button>
      </div>
    </div>
  )
}
